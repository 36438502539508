import React from "react";
import { graphql } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { getImage } from "gatsby-plugin-image";
import styled from "@emotion/styled";
import tw from "twin.macro";
import { BgImage } from "gbimage-bridge";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import LogoCloud from "../components/Repeating/LogoCloud";
import Testimonials from "../components/Repeating/Testimonials";
import CallToAction from "../components/Repeating/CTA";
import ScrollWrapper from "../components/Scroll/ScrollWrapper";
import ScrollNavigation from "../components/Scroll/ScrollNavigation";
import ScrollContent from "../components/Scroll/ScrollContent";

const StyledContent = styled.div`
  ul {
    ${tw`list-disc pl-7 mb-6 flex flex-col space-y-0.5`}
    li {
      ${tw`text-sm lg:text-base tracking-wider lg:tracking-normal`}
    }
  }
  h3 {
    ${tw`font-body normal-case tracking-wider	text-base font-bold`}
  }
  p {
    ${tw`text-sm lg:text-base`}
  }
`;

const Page = ({ data }) => {
  const url = typeof window !== "undefined" ? window.location.pathname : "";

  const heroImages = [
    getImage(data.heroDesktop.childImageSharp.gatsbyImageData),
    {
      ...getImage(data.heroMobile.childImageSharp.gatsbyImageData),
      media: `(max-width: 767px)`,
    },
  ];

  return (
    <Layout headerStyle="overlap">
      <SearchEngineOptimization
        title="Resort Cleaning Services | Hotel Cleaning | HCS"
        description="Need high-quality resort cleaning services nationwide? At Hotel Cleaning Services, that's what we do best. Contact us for the best in cleanliness today!"
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="pt-48 md:pt-64 lg:pt-80 xl:pt-[490px] pb-14 md:pb-52 lg:pb-[415px] relative mb-8 lg:mb-0">
        <BgImage
          className="inset-0 w-full h-full bg-center"
          image={heroImages}
          style={{ position: "absolute" }}
        />
        <div className="container relative">
          <h1 className="mb-4 md:mb-4 md:max-w-[560px]">
            Resort Cleaning Services
          </h1>
          <p className="md:text-xl mb-0 md:max-w-sm">
            Your trusted team for the best in cleanliness.
          </p>
        </div>
      </section>

      <LogoCloud className="relative lg:-top-60 mb-16 md:-mb-6" />

      <section className="relative mb-20 md:mb-32">
        <div className="absolute top-0 w-full"></div>
        <div className="relative container">
          <ScrollWrapper className="grid lg:grid-cols-12 gap-y-16 lg:gap-x-24">
            <ScrollNavigation className="lg:col-start-1 lg:col-span-4">
              <li className="text-primary-500 tracking-wide uppercase font-bold flex items-center lg:before:w-0 lg:before:bg-primary-500 lg:before:h-px lg:before:transition-all lg:before:duration-500 lg:before:ease-linear">
                <AnchorLink
                  to={url + "#anchor-1"}
                  title="About"
                  stripHash
                  className="no-underline"
                />
              </li>
              <li className="text-primary-500 tracking-wide uppercase font-bold flex items-center lg:before:w-0 lg:before:bg-primary-500 lg:before:h-px lg:before:transition-all lg:before:duration-500 lg:before:ease-linear">
                <AnchorLink
                  to={url + "#anchor-2"}
                  title="Services"
                  stripHash
                  className="no-underline"
                />
              </li>
              <li className="text-primary-500 tracking-wide uppercase font-bold flex items-center lg:before:w-0 lg:before:bg-primary-500 lg:before:h-px lg:before:transition-all lg:before:duration-500 lg:before:ease-linear">
                <AnchorLink
                  to={url + "#anchor-3"}
                  title="Benefits"
                  stripHash
                  className="no-underline"
                />
              </li>
            </ScrollNavigation>

            <ScrollContent className="lg:col-end-13 lg:col-span-8">
              <StyledContent>
                <div id="anchor-1" className="mb-14 lg:mb-16">
                  <h2>Our Commitment to You</h2>
                  <p>
                    Our well-qualified staff will ensure your resort receives
                    five stars for cleanliness. Your guests will take notice—and
                    happily return.
                  </p>
                </div>

                <div id="anchor-2" className="mb-14 lg:mb-16">
                  <h2 className="text-mobile-4xl md:text-4xl">Our Services</h2>
                  <p>
                    At Hotel Cleaning Services, we understand every resort has
                    its specific housekeeping and cleaning requirements. For
                    this reason, we deliver a full scope of customized services
                    that include:
                  </p>
                  <ul>
                    <li>Guest Rooms</li>
                    <li>Front Lobby Areas</li>
                    <li>Guest and Employee Restrooms</li>
                    <li>Back of House Service Hallways</li>
                    <li>Kitchens and Restaurants</li>
                    <li>Spas, Exercise Rooms, and Other Guest Amenity Areas</li>
                    <li>Flooring: Hardwood and Carpets</li>
                    <li>Banquet Space Cleaning</li>
                  </ul>
                </div>

                <div id="anchor-3">
                  <h2 className="text-mobile-4xl md:text-4xl">
                    The Benefits of Outsourced Resort Housekeeping
                  </h2>
                  <h3>Decades of Experience in Hospitality</h3>
                  <p>
                    Over 30 years of experience cleaning luxury hotels and
                    resorts has given our company time to perfect our
                    techniques. We guarantee you and your guests will be
                    impressed by the level of cleanliness and professionalism we
                    bring to your property.
                  </p>

                  <h3>Highly-Trained Staff</h3>
                  <p>
                    We perform a thorough vetting process for all staff members.
                    Our team conducts background checks, reference checks, and
                    comprehensive interviews. You can trust that our staff will
                    be well-suited for your property.
                  </p>

                  <h3>The Proper Tools for High-Quality Results</h3>
                  <p>
                    Having properly trained employees is only half the equation
                    when it comes to high-quality housekeeping. Access to the
                    right equipment is an essential component of what we offer
                    to make your hotel sparkle and shine.
                  </p>
                </div>
              </StyledContent>
            </ScrollContent>
          </ScrollWrapper>
        </div>
      </section>

      <Testimonials />
      <CallToAction
        heading={["Contact ", <span className="text-primary-500">Us</span>]}
        subtext="Our highly skilled resort cleaning team is ready to exceed your expectations. Contact us today!        "
      />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Resort Cleaning.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Resort Cleaning.jpg" }
    ) {
      publicURL
    }
    heroDesktop: file(
      relativePath: {
        eq: "services/2.6 Resort Cleaning/1.0 Resort Hero Desktop.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    heroMobile: file(
      relativePath: {
        eq: "services/2.6 Resort Cleaning/1.0 Resort Hero mobile.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;
export default Page;
